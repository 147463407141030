const SUPPORTED_NFT_TYPES =
  "video/webm,video/mp4,image/png,image/jpg,image/jpeg,image/gif,.glb";
const SUPPORTED_AUDIO_GUIDE_TYPES = "audio/mpeg";

const SUPPORTED_IMAGE_TYPES =
  "image/png,image/jpg,image/jpeg,image/gif,image/webp";

const MEDIA_TYPE = {
  VIDEO: "video",
  MODEL: "model",
  IMAGE: "image",
  GIF: "gif",
};

enum VideoExtensions {
  MP4 = "mp4",
  WEBM = "webm",
}

enum ModelExtensions {
  GLB = "glb",
}

const SUPPORTED_VIDEO_EXT = Object.values(VideoExtensions);
const SUPPORTED_MODEL_EXT = Object.values(ModelExtensions);

const PLACEHOLDER_REQUIRED_EXT = [
  ...SUPPORTED_VIDEO_EXT,
  ...SUPPORTED_MODEL_EXT,
];

const FILE_EXT_REGEX = /[^.\\/:*?"<>|\r\n]+$/;

const checkFileExtension = (filename: string, requiredExtensions: string[]) => {
  const extension = filename.match(FILE_EXT_REGEX)?.[0];

  if (!extension) {
    return false;
  }

  return requiredExtensions.includes(extension);
};

const getMediaType = (fileUrl: string) => {
  if (!fileUrl) return;

  if (checkFileExtension(fileUrl, SUPPORTED_VIDEO_EXT)) {
    return MEDIA_TYPE.VIDEO;
  }

  if (checkFileExtension(fileUrl, SUPPORTED_MODEL_EXT)) {
    return MEDIA_TYPE.MODEL;
  }

  return MEDIA_TYPE.IMAGE;
};

export {
  MEDIA_TYPE,
  SUPPORTED_AUDIO_GUIDE_TYPES,
  SUPPORTED_NFT_TYPES,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_VIDEO_EXT,
  SUPPORTED_MODEL_EXT,
  PLACEHOLDER_REQUIRED_EXT,
  checkFileExtension,
  getMediaType,
};
