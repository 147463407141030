import { Nft, NftSortQuery } from "./types";
import requests from "./requests";
import { withClientRequest } from "../requestBuilder/withClientRequest";
import { enrichWithUser } from "../users/client";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { EnrichedObject, EnrichmentProperties } from "../types";
import { enrichItem, exportAddressesByProperties } from "../helpers";
import usersApi from "@/api/users/client";

export async function getSortedEnrichedNfts({
  nftIds,
  order,
  orderDirection,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
}: NftSortQuery) {
  const sortedNfts = await withClientRequest(requests.getSortedNfts)({
    nftIds: nftIds,
    order: order,
    orderDirection: orderDirection,
    page,
    pageSize,
  });

  const enrichedNfts = await enrichWithUser<Nft, "artist">(sortedNfts, {
    artist: true,
  });

  return enrichedNfts;
}

export async function getLatestEnrichedNfts(
  take: number = DEFAULT_PAGE_SIZE,
  skip = 0,
  category?: string,
) {
  const nfts = await withClientRequest(requests.getLatestNfts)(
    take,
    skip,
    category,
  );

  return await usersApi.enrichWithUser<Nft, "artist">(nfts, {
    artist: true,
  });
}

export async function enrichWithNft<
  T extends { [k: string]: any },
  TPropsToEnrich extends string,
>(array: T[], properties: EnrichmentProperties<T>) {
  const listOfAddresses = exportAddressesByProperties(array, properties);
  const nfts = await withClientRequest(requests.getNftsByIdentifiers)(
    listOfAddresses,
  );
  const newArray = array.map((item) => {
    const newItem: any = enrichItem(item, properties, nfts, (nft) => nft.id);
    return newItem;
  });

  return newArray as EnrichedObject<T, TPropsToEnrich, Nft>[];
}

const clientRequests = {
  enrichWithNft,
  getLatestEnrichedNfts,
  createDraftNft: withClientRequest(requests.createDraftNft),
  updateDraftNft: withClientRequest(requests.updateDraftNft),
  sendForApproval: withClientRequest(requests.sendForApproval),
  cancelDraftNft: withClientRequest(requests.cancelDraftNft),
  deleteDraftNft: withClientRequest(requests.deleteDraftNft),
  getLatestNfts: withClientRequest(requests.getLatestNfts),
  getNftsForCollection: withClientRequest(requests.getNftsForCollection),
  getNftsForCollections: withClientRequest(requests.getNftsForCollections),
  getNftsByOwner: withClientRequest(requests.getNftsByOwner),
  getNftsByArtist: withClientRequest(requests.getNftsByArtist),
  getNftsByArtists: withClientRequest(requests.getNftsByArtists),
  getPopularNfts: withClientRequest(requests.getPopularNfts),
  getLikedNfts: withClientRequest(requests.getLikedNfts),
  getNftsByIdentifiers: withClientRequest(requests.getNftsByIdentifiers),
  getSingleNft: withClientRequest(requests.getSingleNft),
  getDraftNftsForArtist: withClientRequest(requests.getDraftNftsForArtist),
  getSortedNfts: withClientRequest(requests.getSortedNfts),
};

export default clientRequests;
