"use client";
import { Quote as QuoteType } from "@/api/contentful/types";
import { User } from "@/api/users/types";
import { Quote } from "@/app/(components)/Basic/Quote";
import { Colors } from "@/shared/constants";
import React, { Fragment } from "react";
import { ArtistItem, ArtistItemCard } from "../ArtistItem";
import { Nft } from "@/api/nfts/types";
import { NoItems } from "@/app/(components)/Basic/NoItems";
import { ShowMoreButton } from "@/app/(components)/Basic/ShowMoreButton";

export interface Props {
  artistItems: User[];
  featuredNfts?: Nft[];
  quotes?: QuoteType[];
  showArtOnHover?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  randomQuoteIndices?: number[] | undefined;
}

const ArtistItemsWithQuotes = ({
  artistItems,
  featuredNfts,
  quotes,
  showArtOnHover,
  hasNextPage,
  fetchNextPage,
  randomQuoteIndices,
}: Props) => {
  if (!artistItems?.length) {
    return <NoItems message="No items to display." />;
  }

  return (
    <>
      <div className={"flex flex-col gap-20"}>
        {artistItems.map((artistItem, index) => {
          const swapItemAndAddQuote = index % 2 != 0;
          const quoteIndex =
            randomQuoteIndices && randomQuoteIndices[Math.floor(index / 2)];
          const isQuoteVisible = swapItemAndAddQuote && quotes && quoteIndex;

          return (
            <Fragment key={index}>
              {showArtOnHover ? (
                <ArtistItem
                  artist={artistItem}
                  featuredNfts={featuredNfts?.filter(
                    (nft) =>
                      nft.artist.toLowerCase() ==
                      artistItem.address?.toLowerCase(),
                  )}
                  swap={swapItemAndAddQuote}
                  color={Object.values(Colors)[index % 3]}
                />
              ) : (
                <ArtistItemCard
                  artist={artistItem}
                  swap={swapItemAndAddQuote}
                  color={Object.values(Colors)[index % 3]}
                />
              )}
              {isQuoteVisible && <Quote quote={quotes[quoteIndex]} />}
            </Fragment>
          );
        })}
      </div>
      {hasNextPage && fetchNextPage && (
        <ShowMoreButton fetchNextPage={fetchNextPage} />
      )}
    </>
  );
};

export default ArtistItemsWithQuotes;
