import { formatAddress } from "@/shared/helpers";
import { ShortUser, User } from "./types";
import { isAddress } from "ethers";
import { Blockchain } from "@/shared/config/blockchain/types";

export function getUsersName(user?: ShortUser, formatUserAddress?: boolean) {
  const address = user?.address;

  return (
    user?.fullName ||
    user?.displayName ||
    (address && formatUserAddress ? formatAddress(address) : address) ||
    "unnamed"
  );
}

export function createShortUser(address: string): ShortUser {
  return {
    id: address,
    address,
    hideProfile: true,
  };
}

export const getUsersHref = (user: ShortUser) => {
  const href = user.displayName
    ? `@${user.displayName}`
    : user.address || user.id;
  return `/${href}`;
};

export const sliceFullName = (fullName: string) => {
  const fullNameParts = fullName.split(" ");
  if (fullNameParts.length === 1) {
    return [fullName];
  }
  const lastName = fullNameParts.pop();
  return [fullNameParts.join(" "), lastName];
};

export const isValidUserPath = (userId: string) => {
  const UUID_REGEX =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return userId.startsWith("@") || isAddress(userId) || UUID_REGEX.test(userId);
};

export const isUserVerified = (user: User, blockchain: Blockchain) => {
  return !!user.blockchain?.includes(blockchain);
};
