import config from "@shared/config/client";
import { RequestInit } from "next/dist/server/web/spec-extension/request";
import queryString from "query-string";
import { OrderDirection } from "../types";
import {
  Nft,
  NftLike,
  OrderNftsBy,
  PopularNft,
  NftSortQuery,
  DraftNft,
  CollectionsNftsQuery,
  CreateNft,
} from "./types";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { ICallableRequestBuilder } from "../requestBuilder/types";

const createDraftNft =
  (request: ICallableRequestBuilder<DraftNft>) => (nft: CreateNft) => {
    return request.call(`${config.apiUrl}/draft-nft`, (init) => ({
      ...init,
      method: "POST",
      credentials: "include",
      headers: {
        ...init.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nft),
    }));
  };

const updateDraftNft =
  (request: ICallableRequestBuilder<DraftNft>) => (nft: CreateNft) => {
    return request.call(`${config.apiUrl}/draft-nft`, (init) => ({
      ...init,
      method: "PUT",
      credentials: "include",
      headers: {
        ...init.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nft),
    }));
  };

const sendForApproval =
  (request: ICallableRequestBuilder<DraftNft>) => (nftId: string) => {
    return request.call(
      `${config.apiUrl}/draft-nft/request-approval/${nftId}`,
      (init) => ({
        ...init,
        method: "PUT",
        credentials: "include",
      }),
    );
  };

const deleteDraftNft =
  (request: ICallableRequestBuilder<DraftNft>) => (nftId: string) => {
    return request.call(`${config.apiUrl}/draft-nft/${nftId}`, (init) => ({
      ...init,
      method: "DELETE",
      credentials: "include",
    }));
  };

const cancelDraftNft =
  (request: ICallableRequestBuilder<DraftNft>) => (nftId: string) => {
    return request.call(
      `${config.apiUrl}/draft-nft/cancel/${nftId}`,
      (init) => ({
        ...init,
        method: "PUT",
        credentials: "include",
      }),
    );
  };

const getLatestNfts =
  (request: ICallableRequestBuilder<Nft[]>) =>
  (count = 20, skip = 0, category?: string): Promise<Nft[]> => {
    const query = {
      order: OrderNftsBy.CreatedAt,
      orderDirection: OrderDirection.DESC,
      take: count,
      skip: skip,
      category,
    };
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts`,
      query,
    });

    return request.call(url);
  };

const getNftsForCollection =
  (request: ICallableRequestBuilder<Nft[]>) =>
  (
    address: string,
    skip: number,
    take: number,
    order?: string,
    orderDirection?: string,
  ): Promise<Nft[]> => {
    const query = {
      take,
      skip,
      order,
      orderDirection,
    };
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/collection/${address}`,
      query,
    });

    return request.call(url);
  };

const getNftsForCollections =
  (request: ICallableRequestBuilder<Nft[]>) =>
  ({ collectionIds, count }: CollectionsNftsQuery): Promise<Nft[]> => {
    const query = {
      collectionIds: collectionIds,
      count,
    };
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/collections`,
      query,
    });

    return request.call(url);
  };

const getNftsByArtist =
  (request: ICallableRequestBuilder<Nft[]>) =>
  ({
    artistId,
    nftId,
    order,
    orderDirection,
    skip = 0,
    take = 20,
    isOnSale,
  }: {
    artistId: string;
    nftId?: string;
    skip: number;
    take: number;
    order?: string;
    orderDirection?: string;
    isOnSale?: boolean;
  }): Promise<Nft[]> => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/artist`,
      query: {
        artist: artistId,
        nftId,
        skip,
        take,
        order,
        orderDirection,
        isOnSale,
      },
    });
    return request.call(url);
  };

const getNftsByArtists =
  (request: ICallableRequestBuilder<Nft[]>) =>
  (artistIds: string[]): Promise<Nft[]> => {
    const query = {
      artists: artistIds,
    };

    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/artists`,
      query,
    });

    return request.call(url);
  };

const getNftsByOwner =
  (request: ICallableRequestBuilder<Nft[]>) =>
  (
    ownerId: string,
    skip: number = 0,
    take = 20,
    order?: string,
    orderDirection?: string,
  ): Promise<Nft[]> => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/owner`,
      query: { owner: ownerId, skip, take, order, orderDirection },
    });
    return request.call(url);
  };

const getPopularNfts =
  (request: ICallableRequestBuilder<PopularNft[]>) =>
  (options?: RequestInit) => {
    return request.call(`${config.apiUrl}/popular/nfts`, (requestInit) => ({
      ...requestInit,
      ...options,
    }));
  };

const getLikedNfts =
  (request: ICallableRequestBuilder<NftLike[]>) =>
  (userId: string, skip: number, take: number) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/likes/user/nft/${userId}`,
      query: {
        skip,
        take,
      },
    });
    return request.call(url);
  };

const getNftsByIdentifiers =
  (request: ICallableRequestBuilder<Nft[]>) => (nftIds: string[]) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts/multiple-by-identifier`,
      query: {
        nftIds,
      },
    });

    return request.call(url);
  };

const getSingleNft =
  (request: ICallableRequestBuilder<Nft>) => (id: string) => {
    return request.call(`${config.apiUrl}/nfts/${id}`);
  };

const getDraftNftsForArtist =
  (request: ICallableRequestBuilder<DraftNft[]>) => (artistId: string) => {
    return request.call(`${config.apiUrl}/draft-nft/artist/${artistId}`);
  };

const getSortedNfts =
  (request: ICallableRequestBuilder<Nft[]>) =>
  ({
    nftIds,
    order,
    orderDirection,
    page = 0,
    pageSize = DEFAULT_PAGE_SIZE,
  }: NftSortQuery) => {
    const query = {
      nftIds: nftIds,
      order: order,
      orderDirection: orderDirection,
      skip: page * pageSize,
      take: pageSize,
    };

    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/nfts`,
      query,
    });

    return request.call(url);
  };

const requests = {
  createDraftNft,
  updateDraftNft,
  sendForApproval,
  cancelDraftNft,
  deleteDraftNft,
  getLatestNfts,
  getNftsForCollection,
  getNftsForCollections,
  getNftsByArtist,
  getNftsByArtists,
  getNftsByOwner,
  getPopularNfts,
  getLikedNfts,
  getNftsByIdentifiers,
  getSingleNft,
  getDraftNftsForArtist,
  getSortedNfts,
};

export default requests;
