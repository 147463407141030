import { Colors } from "@/shared/constants";
import { twJoin, twMerge } from "tailwind-merge";
import DefaultProfile from "@/assets/graphics/default-profile.svg";
import Link from "next/link";
import { User } from "@/api/users/types";
import { getUsersHref, getUsersName, sliceFullName } from "@/api/users/helpers";
import { ImageTypeHandler } from "@/app/(components)/Basic/ImageTypeHandler";

export interface Props {
  artist: User;
  swap: boolean;
  color: Colors;
}

const ArtistItemCard = ({ artist, color, swap }: Props) => {
  const name = artist.fullName ? sliceFullName(artist.fullName!) : [];
  const href = getUsersHref(artist);

  return (
    <>
      <div
        className={twMerge(
          "flex justify-center md:flex-row md:items-center gap-y-2",
          swap ? "flex-col-reverse" : "flex-col",
        )}
      >
        <Link
          href={href}
          className={twMerge(
            "block relative w-full m-automd:m-0  md:max-w-[400px] aspect-square",
            swap && "order-1",
          )}
        >
          {artist.imageUrl ? (
            <ImageTypeHandler
              src={artist.imageUrl}
              placeholder={artist.imagePlaceholder}
              alt={getUsersName(artist) || "profile image"}
              fill
              className="object-cover w-full"
            />
          ) : (
            <DefaultProfile />
          )}
        </Link>
        {/* TODO: Fix fonts */}
        <Link
          href={href}
          className={twMerge(
            "md:relative flex m-auto md:m-0 flex-col items-center md:basis-1/2 md:max-w-[400px]",
            swap ? "md:items-end md:right-10" : "md:items-start md:left-10",
          )}
        >
          <span
            className={`text-h2-sm font-light lg:text-h2 gradient text-${color} whitespace-nowrap`}
          >
            {name[0]}
          </span>
          <span
            className={twJoin(
              "text-h1-sm lg:text-h1 font-semibold whitespace-nowrap",
              `gradient text-${color}`,
            )}
          >
            {name[1]}
          </span>
          <div className="text-secondary text-body-3 mt-2 md:mt-6">
            {artist.displayName}
          </div>
        </Link>
      </div>
    </>
  );
};

export default ArtistItemCard;
