export enum OrderDirection {
  ASC = "asc",
  DESC = "desc",
}

export interface PaginationOptions {
  take: number;
  skip: number;
}

export type EnrichmentPropertiesOptions<T> = {
  name: string;
  addressGetter: (item: T) => string;
};

export type EnrichmentProperties<T> = {
  [K in keyof T]?: boolean | EnrichmentPropertiesOptions<T>;
};

export type EnrichedObject<T, TPropsToEnrich extends string, TEnricher> = {
  [K in keyof T]: K extends TPropsToEnrich ? TEnricher : T[K];
} & { [K in TPropsToEnrich]: TEnricher };
